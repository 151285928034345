import React, { Component } from "react";
import "./profilePage.scss";

class Profile extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: '',
    qrcode: false,
  };

  redirectToHome() {
    window.location.href = '/';
  }

  componentWillMount() {
    if(!localStorage.email) {
      this.redirectToHome()
    }
  }

  render() {
    return (
      localStorage.email ?
      <div className='wrapperProfile container'>
        <div className="detail"><p>User Name:</p><p className='info'>{localStorage.getItem('user_name')}</p></div>
        <div className="detail"><p>User DOB:</p><p className='info'>{localStorage.getItem('dob')}</p></div>
        <div className="detail"><p>User Email:</p><p className="info">{localStorage.getItem('email')}</p></div>
        <div className="detail"><p>User Mobile Number:</p><p className='info'>{localStorage.getItem('mobile_no')}</p></div>
        <div className="detail"><p>Email Verified:</p><p className='info'>{localStorage.getItem('e_verified')}</p></div>
        <div className="detail"><p>User Mobile Verified:</p><p className='info'>{localStorage.getItem('m_verified')}</p></div>
        <div className="detail"><p>EarthID:</p><p className='info'>{localStorage.getItem('earth_id')}</p></div>
        <div className="detail"><p>Trust Score:</p><p className='info'>{localStorage.getItem('trust_score')}</p></div>
      </div> : null
    );
  }
}

export default Profile;
