import React, { Component } from "react";
import CryptoJS from "react-native-crypto-js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Profile from './profilePage/profilePage';
import ReqDocuments from './components/ReqDocument/ReqDocument';
import data from './api'
import { QRCode } from "react-qr-svg";
import { store } from 'react-notifications-component';
import "./App.css";
const cartIcon = require('../src/images/cartIcon.png');
const profileIcon = require('../src/images/profile.png')
const { listenForUserData, listenForServiceProviderResponse, generateqr, getApiKey } = data

let showNotification = true;

class App extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: '',
    qrcode: false,
    response: false,
    email: '',
    mno: '',
    profileDrop: false,
    btnText: 'Login with Earth Id',
    showText: true,
    showModal: true,
    modalHeading: false,
    loginEncryption:'loginDetailsEncryption',
  };

  loader = () => {
    this.setState({ showBtn: false, modalHeading: true });
    this.setState({ qrcode: true, showSpinner: false })
    if (!this.state.showModal) {
      this.setState({
        showModal: true
      })
    }
    if (this.state.loggedIn) {
      localStorage.removeItem('email');
      localStorage.removeItem('mobile_no');
      localStorage.removeItem('user_name');
      localStorage.removeItem('dob');
      localStorage.removeItem('e_verified');
      localStorage.removeItem('trust_score');
      localStorage.removeItem('earth_id');
      localStorage.removeItem('m_verified');
      this.setState({
        loggedIn: false,
        btnText: 'Login with Earth Id',
        qrcode: true,
        response: false,
        showModal: false
      })
      setTimeout(() => {
        document.getElementById('exampleModal').click();
      }, 0);
      this.redirectToHome()
    }
  }

  componentDidMount() {
    if (localStorage.email != null) {
      this.setState({
        loggedIn: true,
        btnText: 'LogOut'
      })
    }
    if (!this.state.apiKey) {
      this.state.apiKey = getApiKey();
    }
  }

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
  }

  listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false
      })
    })

    listenForUserData(async (err, res) => {
      
      this.setState({
        showQr: false,
        showSpinner: false,
      })
       if(res.newreq.permission == 'denied') {
        this.setState({
          response: false,
          showModal: false,
        })
        setTimeout(() => {
          document.getElementById('exampleModal').click();
          if(showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Error!",
              message: `User Didn't Authorize!`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.updateNotificationState();
          }
        }, 0);
      } else if(res && res.newreq.ciphertext) {
        let bytes = []
        bytes  = await CryptoJS.AES.decrypt(res.newreq.ciphertext, this.state.loginEncryption);  
        let decryptedData = await JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        localStorage.setItem('email', decryptedData.userEmail);
        localStorage.setItem('mobile_no', decryptedData.userMobileNo);
        localStorage.setItem('user_name', decryptedData.fname);
        localStorage.setItem('dob', decryptedData.dob);
        localStorage.setItem('e_verified', decryptedData.emailVerified);
        localStorage.setItem('trust_score', decryptedData.trustScore);
        localStorage.setItem('earth_id', decryptedData.earthId);
        localStorage.setItem('m_verified', decryptedData.mobileVerified);
        this.setState({
          response: true,
          showSpinner: false,
          loggedIn: true,
          showModal: false,
          btnText: 'LogOut',
          email: decryptedData.userEmail,
          mno: decryptedData.userMobileNo,
          fname: decryptedData.fname,
          userEmail: decryptedData.userEmail,
          userMobileNo: decryptedData.userMobileNo,
          dob: decryptedData.dob,
          emailVerified: decryptedData.emailVerified === '' ? undefined : decryptedData.emailVerified,
          score: decryptedData.trustScore,
          earthid: decryptedData.earthId,
          mobileVerified: decryptedData.mobileVerified === '' ? undefined : decryptedData.mobileVerified
        })
        setTimeout(() => {
          document.getElementById('exampleModal').click();
          if(showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Success!",
              message: `Login Successful`,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.updateNotificationState();
          }
        }, 0);
      } else if (res.newreq.requestType === 'login') {
        localStorage.setItem('email', res.newreq.userEmail);
        localStorage.setItem('mobile_no', res.newreq.userMobileNo);
        localStorage.setItem('user_name', res.newreq.fname);
        localStorage.setItem('dob', res.newreq.dob);
        localStorage.setItem('e_verified', res.newreq.emailVerified);
        localStorage.setItem('trust_score', res.newreq.score);
        localStorage.setItem('earth_id', res.newreq.earthId);
        localStorage.setItem('m_verified', res.newreq.mobileVerified);
        this.setState({
          response: true,
          showSpinner: false,
          loggedIn: true,
          showModal: false,
          btnText: 'LogOut',
          email: res.newreq.userEmail,
          mno: res.newreq.userMobileNo,
          fname: res.newreq.fname,
          userEmail: res.newreq.userEmail,
          userMobileNo: res.newreq.userMobileNo,
          dob: res.newreq.dob,
          emailVerified: res.newreq.emailVerified === '' ? undefined : res.newreq.emailVerified,
          score: res.newreq.score,
          earthid: res.newreq.earthId,
          mobileVerified: res.newreq.mobileVerified === '' ? undefined : res.newreq.mobileVerified
        })
        setTimeout(() => {
          document.getElementById('exampleModal').click();
          if(showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Success!",
              message: `Login Successful`,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.updateNotificationState();
          }
        }, 0);
      }

    })
  }

  generateQrCodeData = () => {
    generateqr().then(data => {
      this.listenForSocket();
      data = JSON.parse(data)
      data.encryptionkey = this.state.loginEncryption
      data.requestType = "login";
      this.setState({
        showQr: true,
        qrCodeData: JSON.stringify(data),
        apiKey: this.state.apikey,
        qrcode: false,
      })
    }).catch((err) => {
      alert(err)
    })
  }

  qrcode = () => {
    let { qrCodeData } = this.state
    return (
      <div className="qr-container">
        <QRCode
          value={`${qrCodeData}`}
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          style={{ width: 256 }}
        />
      </div>
    );
  };

  databox = () => {
    let { emailVerified, mobileVerified } = this.state
    return (
      <div className="data-container">
        <div className="detail"><p>User Name:</p><p className='info'>{localStorage.getItem('user_name')}</p></div>
        <div className="detail"><p>User DOB:</p><p className='info'>{localStorage.getItem('dob')}</p></div>
        <div className="detail"><p>User Email:</p><p className="info">{localStorage.getItem('email')}</p></div>
        <div className="detail"><p>User Mobile Number:</p><p className='info'>{localStorage.getItem('mobile_no')}</p></div>
        {emailVerified !== undefined ? <div className="detail"><p>Email Verified:</p><p className='info'>{localStorage.getItem('e_verified')}</p></div> : null}
        {mobileVerified !== undefined ? <div className="detail"><p>User Mobile Verified:</p><p className='info'>{localStorage.getItem('m_verified')}</p></div> : null}
        <div className="detail"><p>EarthID:</p><p className='info'>{localStorage.getItem('earth_id')}</p></div>
        <div className="detail"><p>Trust Score:</p><p className='info'>{localStorage.getItem('trust_score')}</p></div>
      </div>
    );
  }

  redirectToProfile() {
    window.location.href = '/profile';
  }

  redirectToHome() {
    window.location.href = '/';
  }

  redirectToDocuments() {
    window.location.href = '/documents';
  }

  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div"><img className="spinner" src={require("./spinner.gif")} /></div>
    ) : null
    return (
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="collapse navbar-collapse pull-right container" id="navbarTogglerDemo01">
            <div className="clickable" onClick={() => this.redirectToHome()}><img src={require("./fcart2.png")} alt="logo" className="logo" /> <span className="logo-text">&nbsp;Fcart</span></div>
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            </ul>

            {localStorage.user_name ? <div>
              <label className="welcomeLabel">{`Welcome, ${localStorage.getItem('user_name')}`}</label>
            </div> : null}

            <div className='profileAccess'>
              {!this.state.loggedIn ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={this.loader}>{this.state.btnText}</button> : null}
              <div className="clickable"><img className='cartIcon' src={cartIcon} /> <span className='cartText'>Cart</span></div>
              {this.state.loggedIn ? <div className='dropDown'>
                <img className='profileIcon' src={profileIcon} onClick={() => { this.setState({ profileDrop: !this.state.profileDrop }) }} />
                {this.state.profileDrop ? <div>
                  <ul className="dropdown-profile">
                    <li className="dropdown-list1" onClick={() => this.redirectToProfile()}>My Profile</li>
                    <li className="dropdown-list1" onClick={() => this.redirectToDocuments()}> Submit Kyc Documents</li>
                    <li className="dropdown-list" onClick={this.loader}>LogOut</li>
                  </ul>
                </div> : ""}
              </div> : null}
            </div>
          </div>
        </nav>
        <header>
          {window.location.pathname == '/' ?
            <div className="hero-section">
              <div className='shoppingCart'>
              </div>
              <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ?
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading && !this.state.showSpinner ? 'Use Earth ID app to scan the QR code' : 'Waiting for user authorization'}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response ? null : this.state.qrcode ? this.generateQrCodeData() : null}
                              {this.state.showQr ? this.qrcode() : this.state.showSpinner ? <img className="spinner" src={require("./spinner.gif")} /> : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary p-btn" data-dismiss="modal">Close</button>
                          </div>
                        </div>
                        : null}
                    </div>
                  }
                </div>
              </div>
            </div> : ''}
          <div className='content'>
            <Router>
              <Switch>
                <Route path="/profile" component={Profile} />
                <Route path="/documents" component={ReqDocuments} />
              </Switch>
            </Router>
          </div>
        </header>
      </div>
    );
  }
}

export default App;