import React, { Component } from "react";
import CryptoJS from "react-native-crypto-js";
import "./ReqDocument.scss";
import { QRCode } from "react-qr-svg";
import data from '../../api';
import { store } from 'react-notifications-component';
const panCard = require('../../images/pancard.jpg')
const drivingLicence = require('../../images/driving-license.jpeg')
const voterId = require('../../images/voterid.jpeg')
const { listenForUserData, listenForServiceProviderResponse, generateqr } = data

let showNotification = true;

class ReqDocuments extends Component {
  state = {
    profileDrop: false,
    requestNo: '',
    openModal: false,
    qrcode: false,
    response: false,
    showSpinner: false,
    modalHeading: false,
    selectedList: 'List of Documents',
    documents: [],
    decodedDocbase64: '',
    documentEncryption:'documentDetailsEncryption',
    loginEncryption:'loginDetailsEncryption',


  }

  redirectToHome() {
    window.location.href = '/';
  }

  componentWillMount() {
    if (!localStorage.email) {
      this.redirectToHome()
    }
    // setTimeout(() => {
    //   this.setState({
    //     response: true,
    //     showModal: true,
    //     showSpinner: false,
    //     DeniedHeading: false,
    //   })
    // }, 500);
  }

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
  }

   listenForSocket = async () => {
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false
      })
    })
    
    listenForUserData(async(err, res) =>  {
      console.log('data::::::::ddddd11111::::::',res)
      this.setState({
        showQr: false,
        showSpinner: true,
      })
      if(res.newreq.permission == 'denied') {
        this.setState({
          profileDrop: false,
          requestNo: '',
          openModal: false,
          qrcode: false,
          response: false,
          showSpinner: false,
          modalHeading: false,
          selectedList: 'List of Documents',
        })
        setTimeout(() => {
          document.getElementById('exampleModal').click();
          if (showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Error!",
              message: `User Didn't Authorize!`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.updateNotificationState();
          }
        }, 0);
      }
      else if(res && res.newreq.ciphertext) {
        let bytes = []
            bytes  = await CryptoJS.AES.decrypt(res.newreq.ciphertext, this.state.documentEncryption).toString(CryptoJS.enc.Utf8)
            console.log('bytes', bytes)
            let decryptedData = await JSON.parse(bytes);
        console.log('loginDetails', decryptedData)
        localStorage.setItem('email', decryptedData.userEmail);
        localStorage.setItem('mobile_no', decryptedData.userMobileNo);
        localStorage.setItem('user_name', decryptedData.fname);
        localStorage.setItem('dob', decryptedData.dob);
        localStorage.setItem('e_verified', decryptedData.emailVerified);
        localStorage.setItem('trust_score', decryptedData.trustScore);
        localStorage.setItem('earth_id', decryptedData.earthId);
        localStorage.setItem('m_verified', decryptedData.mobileVerified);
        
        setTimeout(async() => {
          this.setState({
            response: true,
            showModal: true,
            showSpinner: false,
            btnText: 'LogOut',
            email: decryptedData.userEmail,
            mno: decryptedData.userMobileNo,
            fname: decryptedData.fname,
            userEmail: decryptedData.userEmail,
            userMobileNo: decryptedData.userMobileNo,
            dob: decryptedData.dob,
            emailVerified: decryptedData.emailVerified === '' ? undefined : decryptedData.emailVerified,
            score: decryptedData.trustScore,
            earthid: decryptedData.earthId,
            mobileVerified: decryptedData.mobileVerified === '' ? undefined : decryptedData.mobileVerified,
            DeniedHeading: false,
            documents: decryptedData.documents
          })
          // let bytes  = await CryptoJS.AES.decrypt(this.state.documents[0].data, this.state.documentEncryption);
          // this.setState({
          //   decodedDocbase64: bytes.toString(CryptoJS.enc.Utf8)
          // }) 
          console.log('ggfgfgfgfg::::::', this.state.documents, this.state.showModal, this.state.decodedDocbase64)
          document.getElementById('exampleModal').click();
        }, 0);
      } else if (res && res.newreq.requestType === "document") {
        localStorage.setItem('email', res.newreq.userEmail);
        localStorage.setItem('mobile_no', res.newreq.userMobileNo);
        localStorage.setItem('user_name', res.newreq.fname);
        localStorage.setItem('dob', res.newreq.dob);
        localStorage.setItem('e_verified', res.newreq.emailVerified);
        localStorage.setItem('trust_score', res.newreq.score);
        localStorage.setItem('earth_id', res.newreq.earthId);
        localStorage.setItem('m_verified', res.newreq.mobileVerified);
        
        setTimeout(() => {
          this.setState({
            response: true,
            showModal: true,
            showSpinner: false,
            btnText: 'LogOut',
            email: res.newreq.userEmail,
            mno: res.newreq.userMobileNo,
            fname: res.newreq.fname,
            userEmail: res.newreq.userEmail,
            userMobileNo: res.newreq.userMobileNo,
            dob: res.newreq.dob,
            emailVerified: res.newreq.emailVerified === '' ? undefined : res.newreq.emailVerified,
            score: res.newreq.score,
            earthid: res.newreq.earthId,
            mobileVerified: res.newreq.mobileVerified === '' ? undefined : res.newreq.mobileVerified,
            DeniedHeading: false,
            documents: res.newreq.documents
          })
          console.log('ggfgfgfgfg::::::', this.state.documents, this.state.showModal)
          document.getElementById('exampleModal').click();
        }, 0);

      }

    })
  }

  generateQrCodeData = () => {
    generateqr().then(data => {
      this.listenForSocket();
      data = JSON.parse(data)
      data.encryptionkey = this.state.documentEncryption;
      data.requestType = "document";
      data.earthId = localStorage.getItem('earth_id');
      this.setState({
        showQr: true,
        qrCodeData: JSON.stringify(data),
        apiKey: this.state.apikey,
        qrcode: false
      })
    }).catch((err) => {
      alert(err)
    })
  }

  qrcode = () => {
    let { qrCodeData } = this.state
    return (
      <div className="qr-container">
        <QRCode
          value={`${qrCodeData}`}
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          style={{ width: 256 }}
        />
      </div>
    );
  };

  render() {
    document.data = ''
    if (!localStorage.email) {
      return null;
    }
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div"><img className="spinner" src={require('../../../src/spinner.gif')} /></div>
    ) : null
    return (
      <div className='wrapperDocuments'>
        <div className="modalFade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            {this.state.response ? '' :
              <div>
                {this.state.openModal ?
                  <div className="modal-content" style={{ width: '500px' }}>
                    <div className="modal-header">
                      <h4>
                        {this.state.modalHeading && !this.state.showSpinner ? 'Use Earth ID app to scan the QR code' : 'Waiting for user authorization'}
                      </h4>
                    </div>
                    <div className="modal-body">
                      <div className="pop-upbox">
                        {showButton}
                        {this.state.response ? null : this.state.qrcode ? this.generateQrCodeData() : null}
                        {this.state.showQr ? this.qrcode() : this.state.showSpinner ? <img className="spinner" src={require('../../../src/spinner.gif')} alt='spin' /> : null}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary p-btn" data-dismiss="modal" onClick={() => { this.setState({ openModal: false }) }} >Close</button>
                    </div>
                  </div>
                  : null}
              </div>}
          </div>
        </div>
        {this.state.openModal ? '' :
          <div className='docDopdown'>
            {/* <div className='documentList'>{this.state.selectedList}
              <i class='fas fa-angle-down downArrow' onClick={() => { this.setState({ profileDrop: !this.state.profileDrop }) }}></i>
            </div>
            {this.state.profileDrop ? <div>
              <ul className="document-list">
                <li className="document" onClick={() => { this.setState({ selectedList: 'PAN CARD', profileDrop: false, qrcode: true, modalHeading: true }) }}>PAN CARD</li>
                <li className="document" onClick={() => { this.setState({ selectedList: 'DRIVING LICENCE', profileDrop: false, qrcode: true, modalHeading: true }) }}>DRIVING LICENCE</li>
                <li className="document" onClick={() => { this.setState({ selectedList: 'VOTER ID', profileDrop: false, qrcode: true, modalHeading: true }) }}>VOTER ID</li>
              </ul>
            </div> : ""} */}
            <button className='register' onClick={() => { this.setState({ openModal: true, profileDrop: false, qrcode: true, modalHeading: true }) }} >Submit Document with Earth Id</button>
          </div>}
          {console.log(this.state)}
        {this.state.showModal ?
        (this.state.documents.map((document) => {
          console.log('fffff::::',document)
          return (<div className='doc-list'>
            <div className='modalMain'>
            <div className='modalMainSub'>
              <h2>{document.name}</h2>
              {/* <img className='aadharIcon' src={document == 'PAN CARD' ? panCard : document == 'DRIVING LICENSE' ? drivingLicence : voterId} /> */}
              {document.contentType == 'pdf' ? <embed width="100%" height="100%" name="plugin" src={`data:application/pdf;base64,${document.data}`}></embed> : <img className='aadharIcon' src={`data:image/jpeg;base64,${document.data}`}  style={{width: '300px'}} />}
            </div>
          </div>
          </div>)
  })) : console.log('hi hi hi:::::')}
      </div>
    );
  }
}

// 0: "PAN CARD"
// 1: "DRIVING LICENSE"
// 2: "VOTER ID"

export default ReqDocuments;